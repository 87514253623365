<template>
  <section class="section-block">
    <SectionTitle title="功能列表顯示設定" btn="編輯" @edit="showEditModal = true" />
    <el-form :model="memberCard" label-position="left" label-width="200px">
      <el-form-item v-if="useSalesRecord" label="會員顯示消費金額">
        <el-switch
          v-model="memberCard.showMember"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
          @change="updateMemberCard('showMember')"
        />
      </el-form-item>

      <el-form-item v-if="memberCard.showMember" label="會員顯示消費明細">
        <el-switch
          v-model="memberCard.showMemberDetail"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
          @change="updateMemberCard('showMemberDetail')"
        />
      </el-form-item>

      <el-form-item label="會員顯示常用地址">
        <el-switch
          v-model="funcMenu.userInfoMoreAddress"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
          @change="updateFunctionMenu"
        />
      </el-form-item>

      <el-form-item v-if="usePospalBalance" label="會員顯示瑞乘消費紀錄">
        <el-switch
          v-model="pospal.posPalMemberTransaction"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
          @change="updateFunctionMenu"
        />
      </el-form-item>

      <el-form-item v-if="usePospalTransaction" label="會員顯示瑞乘會員餘額紀錄">
        <el-switch
          v-model="pospal.posPalMemberWallet"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
          @change="updateFunctionMenu"
        />
      </el-form-item>

      <el-form-item v-if="usePospalPassProduct" label="會員顯示瑞乘會員次卡紀錄">
        <el-switch
          v-model="pospal.posPalMemberPassProduct"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
          @change="updateFunctionMenu"
        />
      </el-form-item>

      <el-form-item v-if="useExternalTransaction" label="會員顯示第三方消費紀錄">
        <el-switch
          v-model="external.externalMemberTransaction"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
          @change="updateFunctionMenu"
        />
      </el-form-item>
      <el-form-item v-if="useExternalTransaction && external.externalMemberTransaction" label="限定顯示區間">
        <p>{{ enableClientDisplayTimeRange ? '限定' : '不限定' }}</p>
      </el-form-item>
      <el-form-item v-if="external.externalMemberTransaction && enableClientDisplayTimeRange" label="開始時間">
        <p>{{ clientDisplayTimeRangeStartAt ? formatDate(clientDisplayTimeRangeStartAt, 'YYYY/MM/DD HH:mm') : '-' }}</p>
      </el-form-item>
      <el-form-item v-if="external.externalMemberTransaction && enableClientDisplayTimeRange" label="結束時間">
        <p>{{ clientDisplayTimeRangeEndAt ? formatDate(clientDisplayTimeRangeEndAt, 'YYYY/MM/DD HH:mm') : '-目前更新時間' }}</p>
      </el-form-item>
    </el-form>
    <MemberCardFuncShowEditModal
      v-if="showEditModal"
      :useExternalTransaction="useExternalTransaction"
      :usePospalTransaction="usePospalTransaction"
      :usePospalBalance="usePospalBalance"
      :configData="configData"
      :functionMenuConfig="functionMenuConfig"
      @close="showEditModal = false"
      @refresh="refresh"
    />
  </section>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import {
  GetSalesConfig,
  UpdateShowMember,
} from '@/api/sales'
import { UpdateMemberCenterConfig, GetExternalTransactionConfig } from '@/api/member/memberCenter'
import { mapGetters } from 'vuex'
import { usePermissions } from '@/use/permissions'
import { computed, ref } from 'vue'
import { useShop } from '@/use/shop'
import { find, set as ObjSet, get } from 'lodash'
import { formatDate } from '@/utils/date'
import MemberCardFuncShowEditModal from './MemberCardFuncShowEditModal.vue'
export default {
  name: 'MemberCardFuncShow',
  components: {
    SectionTitle,
    MemberCardFuncShowEditModal,
  },
  props: {
    functionMenuConfig: {
      type: Array, default: () => [],
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const showEditModal = ref(false)
    const { checkAction } = usePermissions()
    const usePospalTransaction = computed(() => checkAction('adminView.memberCenterConfig.posPalMemberTransaction'))
    const usePospalPassProduct = computed(() => checkAction('adminView.memberCenterConfig.posPalMemberPassProduct'))
    const usePospalBalance = computed(() => checkAction('adminView.memberCenterConfig.findPosPalMemberWalletRecord'))
    const useExternalTransaction = computed(() => checkAction('adminView.memberCenterConfig.memberExternalTransaction'))
    const useSalesRecord = computed(() => checkAction('admin.salesRecord.page'))

    return { usePospalTransaction, usePospalBalance, useExternalTransaction, showEditModal, formatDate, usePospalPassProduct, useSalesRecord }
  },
  data: () => ({
    memberCard: {
      showMember: false,
      showMemberDetail: false,
    },
    pospal: {
      posPalMemberWallet: false,
      posPalMemberTransaction: false,
      posPalMemberPassProduct: false,
    },

    funcMenu: {
      userInfoMoreAddress: false,
    },
    external: {
      externalMemberTransaction: false,
    },
    enableClientDisplayTimeRange: false,
    clientDisplayTimeRangeStartAt: null,
    clientDisplayTimeRangeEndAt: null,
  }),
  computed: {
    ...mapGetters(['shop']),
    configData () {
      return {
        showMember: this.memberCard.showMember,
        showMemberDetail: this.memberCard.showMemberDetail,
        posPalMemberWallet: this.pospal.posPalMemberWallet,
        posPalMemberTransaction: this.pospal.posPalMemberTransaction,
        posPalMemberPassProduct: this.pospal.posPalMemberPassProduct,
        userInfoMoreAddress: this.funcMenu.userInfoMoreAddress,
        externalMemberTransaction: this.external.externalMemberTransaction,
        enableClientDisplayTimeRange: this.enableClientDisplayTimeRange,
        clientDisplayTimeRangeStartAt: this.clientDisplayTimeRangeStartAt,
        clientDisplayTimeRangeEndAt: this.clientDisplayTimeRangeEndAt,
      }
    },
  },
  watch: {
    functionMenuConfig: {
      handler () {
        this.syncData()
      },
      deep: true,
    },
  },
  async mounted () {
    await this.refresh()
  },
  methods: {
    async refresh () {
      if (this.useSalesRecord) await this.getSalseConfig()
      if (this.useExternalTransaction) await this.getExternalTransactionConfig()
      this.syncData()
    },
    syncData () {
      setTimeout(() => {
        const config = this.functionMenuConfig
        const posPalMemberWallet = find(config, { type: 'posPalMemberWallet' })
        const posPalMemberTransaction = find(config, { type: 'posPalMemberTransaction' })
        const posPalMemberPassProduct = find(config, { type: 'posPalMemberPassProduct' })
        const userInfoMoreAddress = find(config, { type: 'userInfoMoreAddress' })
        const externalMemberTransaction = find(config, { type: 'memberExternalTransaction' })
        this.pospal.posPalMemberWallet = get(posPalMemberWallet, 'enable') || false
        this.pospal.posPalMemberTransaction = get(posPalMemberTransaction, 'enable') || false
        this.pospal.posPalMemberPassProduct = get(posPalMemberPassProduct, 'enable') || false
        this.funcMenu.userInfoMoreAddress = get(userInfoMoreAddress, 'enable') || false
        this.external.externalMemberTransaction = get(externalMemberTransaction, 'enable') || false
      }, 500)
    },
    async updateFunctionMenu () {
      const config = this.functionMenuConfig
      const posPalMemberWallet = find(config, { type: 'posPalMemberWallet' })
      const posPalMemberTransaction = find(config, { type: 'posPalMemberTransaction' })
      const posPalMemberPassProduct = find(config, { type: 'posPalMemberPassProduct' })
      const userInfoMoreAddress = find(config, { type: 'userInfoMoreAddress' })
      const externalMemberTransaction = find(config, { type: 'memberExternalTransaction' })
      if (posPalMemberWallet) {
        ObjSet(posPalMemberWallet, 'enable', this.pospal.posPalMemberWallet)
      } else {
        config.push({ type: 'posPalMemberWallet', enable: this.pospal.posPalMemberWallet })
      }
      if (posPalMemberPassProduct) {
        ObjSet(posPalMemberPassProduct, 'enable', this.pospal.posPalMemberPassProduct)
      } else {
        config.push({ type: 'posPalMemberPassProduct', enable: this.pospal.posPalMemberPassProduct })
      }
      if (posPalMemberTransaction) {
        ObjSet(posPalMemberTransaction, 'enable', this.pospal.posPalMemberTransaction)
      } else {
        config.push({ type: 'posPalMemberTransaction', enable: this.pospal.posPalMemberTransaction })
      }
      if (userInfoMoreAddress) {
        ObjSet(userInfoMoreAddress, 'enable', this.funcMenu.userInfoMoreAddress)
      } else {
        config.push({ type: 'userInfoMoreAddress', enable: this.funcMenu.userInfoMoreAddress })
      }
      if (externalMemberTransaction) {
        ObjSet(externalMemberTransaction, 'enable', this.external.externalMemberTransaction)
      } else {
        config.push({ type: 'memberExternalTransaction', enable: this.external.externalMemberTransaction })
      }
      const [, err] = await UpdateMemberCenterConfig({
        shopId: this.shop,
        functionMenuSetting: config,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
    },
    async getSalseConfig () {
      try {
        const res = await GetSalesConfig({ shopId: this.shop })
        this.memberCard = res
        // this.formData.identityMode = this.memberCard.identityMode
        // this.formData.showMemberDetail = this.memberCard.showMemberDetail
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },
    async getExternalTransactionConfig () {
      const [res, err] = await GetExternalTransactionConfig({
        shopId: this.shop,
      })
      if (err) return this.$message.error(err)
      this.enableClientDisplayTimeRange = res.enableClientDisplayTimeRange
      this.clientDisplayTimeRangeStartAt = res.clientDisplayTimeRangeStartAt
      this.clientDisplayTimeRangeEndAt = res.clientDisplayTimeRangeEndAt
    },
    async updateMemberCard (mode) {
      try {
        await UpdateShowMember({
          shopId: this.shop,
          showMember:
            mode === 'showMember' ? this.memberCard.showMember : undefined,
          identityMode:
            mode === 'identityMode' ? this.memberCard.identityMode : undefined,
          showMemberDetail:
            mode === 'showMemberDetail'
              ? this.memberCard.showMemberDetail
              : undefined,
          enableDisplayTransaction: mode === 'enableDisplayTransaction' ? this.memberCard.enableDisplayTransaction : undefined,
          enableDisplayBalance: mode === 'enableDisplayBalance' ? this.memberCard.enableDisplayBalance : undefined,
        })
        this.$message.success('更新成功 !')
        await this.getSalseConfig()
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },
  },
}
</script>
