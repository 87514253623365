<template>
  <BaseDialog
    title="功能列表顯示設定"
    class="member-center-show-edit-modal"
    width="670px"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="updateFunctionMenu"
  >
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="left" label-width="200px">
      <el-form-item label="會員顯示消費金額" prop="showMember">
        <el-switch
          v-model="formData.showMember"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
        />
      </el-form-item>
      <el-form-item v-if="formData.showMember" prop="showMemberDetail" label="會員顯示消費明細">
        <el-switch
          v-model="formData.showMemberDetail"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
        />
      </el-form-item>
      <el-form-item label="會員顯示常用地址">
        <el-switch
          v-model="formData.userInfoMoreAddress"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
        />
      </el-form-item>
      <el-form-item v-if="usePospalBalance" prop="posPalMemberTransaction" label="會員顯示瑞乘消費紀錄">
        <el-switch
          v-model="formData.posPalMemberTransaction"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
        />
      </el-form-item>
      <el-form-item v-if="usePospalTransaction" prop="posPalMemberWallet" label="會員顯示瑞乘會員餘額紀錄">
        <el-switch
          v-model="formData.posPalMemberWallet"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
        />
      </el-form-item>
      <el-form-item v-if="usePospalTransaction" prop="posPalMemberPassProduct" label="會員顯示瑞乘會員次卡紀錄">
        <el-switch
          v-model="formData.posPalMemberPassProduct"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
        />
      </el-form-item>
      <el-form-item v-if="useExternalTransaction" prop="externalMemberTransaction" label="會員顯示第三方消費紀錄">
        <el-switch
          v-model="formData.externalMemberTransaction"
          class="switch-input"
          active-text="開啟顯示"
          inactive-text="關閉顯示"
        />
      </el-form-item>
      <el-form-item v-if="useExternalTransaction && formData.externalMemberTransaction" prop="enableClientDisplayTimeRange" label="限定顯示區間">
        <el-switch
          v-model="formData.enableClientDisplayTimeRange"
          class="switch-input"
          active-text="限定"
          inactive-text="不限定"
        />
      </el-form-item>
      <el-form-item v-if="formData.externalMemberTransaction && formData.enableClientDisplayTimeRange" prop="clientDisplayTimeRangeStartAt" label="開始時間">
        <el-date-picker
          v-model="formData.clientDisplayTimeRangeStartAt"
          type="datetime"
          placeholder="選擇日期"
          format="yyyy/MM/dd HH:mm"
          date-format="yyyy/mm/dd"
          time-format="HH:mm"
        />
      </el-form-item>
      <el-form-item v-if="formData.externalMemberTransaction && formData.enableClientDisplayTimeRange" prop="clientDisplayTimeRangeEndAt" label="結束時間">
        <el-date-picker
          v-model="formData.clientDisplayTimeRangeEndAt"
          type="datetime"
          placeholder="選擇日期"
          format="yyyy/MM/dd HH:mm"
          date-format="yyyy/mm/dd"
          time-format="HH:mm"
        />
      </el-form-item>
    </el-form>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { UpdateMemberCenterConfig, UpdateExternalTransactionConfig } from '@/api/member/memberCenter'
import { values, find, set as ObjSet } from 'lodash'
import store from '@/store'
import formUtils from '@/utils/form'
import { UpdateShowMember } from '@/api/sales'
export default defineComponent({
  name: 'MemberCardFuncShowEditModal',
  components: { BaseDialog },
  props: {
    configData: { type: Object, default: () => {} },
    useExternalTransaction: { type: Boolean, default: () => false },
    usePospalTransaction: { type: Boolean, default: () => false },
    usePospalBalance: { type: Boolean, default: () => false },
    functionMenuConfig: { type: Array, default: () => [] },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const { initFormData, formData, formRef, loading } = useBaseForm()
    initFormData({
      showMember: false,
      showMemberDetail: false,
      posPalMemberWallet: false,
      posPalMemberPassProduct: false,
      posPalMemberTransaction: false,
      userInfoMoreAddress: false,
      externalMemberTransaction: false,
      enableClientDisplayTimeRange: false,
      clientDisplayTimeRangeStartAt: null,
      clientDisplayTimeRangeEndAt: null,
    })
    const formRules = computed(() => {
      const rules = {
        clientDisplayTimeRangeStartAt: [noEmptyRules()],
      }
      return rules
    })

    const updateFunctionMenu = async () => {
      loading.value = true
      if (!await formUtils.checkForm(formRef.value)) {
        loading.value = false
        return
      }
      const config = props.functionMenuConfig
      const posPalMemberWallet = find(config, { type: 'posPalMemberWallet' })
      const posPalMemberTransaction = find(config, { type: 'posPalMemberTransaction' })
      const posPalMemberPassProduct = find(config, { type: 'posPalMemberPassProduct' })
      const userInfoMoreAddress = find(config, { type: 'userInfoMoreAddress' })
      const externalMemberTransaction = find(config, { type: 'memberExternalTransaction' })
      if (posPalMemberWallet) {
        ObjSet(posPalMemberWallet, 'enable', formData.posPalMemberWallet)
      } else {
        config.push({ type: 'posPalMemberWallet', enable: formData.posPalMemberWallet })
      }
      if (posPalMemberPassProduct) {
        ObjSet(posPalMemberPassProduct, 'enable', formData.posPalMemberPassProduct)
      } else {
        config.push({ type: 'posPalMemberPassProduct', enable: formData.posPalMemberPassProduct })
      }
      if (posPalMemberTransaction) {
        ObjSet(posPalMemberTransaction, 'enable', formData.posPalMemberTransaction)
      } else {
        config.push({ type: 'posPalMemberTransaction', enable: formData.posPalMemberTransaction })
      }
      if (userInfoMoreAddress) {
        ObjSet(userInfoMoreAddress, 'enable', formData.userInfoMoreAddress)
      } else {
        config.push({ type: 'userInfoMoreAddress', enable: formData.userInfoMoreAddress })
      }
      if (externalMemberTransaction) {
        ObjSet(externalMemberTransaction, 'enable', formData.externalMemberTransaction)
      } else {
        config.push({ type: 'memberExternalTransaction', enable: formData.externalMemberTransaction })
      }
      const [, err] = await UpdateMemberCenterConfig({
        shopId: shopId.value,
        functionMenuSetting: config,
      })
      loading.value = false
      if (err) return window.$message.error(err)
      await updateMemberCard()
      // window.$message.success('更新成功！')
      // emit('refresh')
      // emit('close')
    }
    const updateMemberCard = async () => {
      try {
        await UpdateShowMember({
          shopId: shopId.value,
          showMember: formData.showMember,
          identityMode: undefined,
          showMemberDetail: formData.showMemberDetail,
          enableDisplayTransaction: undefined,
          enableDisplayBalance: undefined,
        })
        // this.$message.success('更新成功 !')
        await updateExternalTransactionConfig()
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    }
    const updateExternalTransactionConfig = async () => {
      const [, err] = await UpdateExternalTransactionConfig({
        shopId: shopId.value,
        enableClientDisplayTimeRange: formData.enableClientDisplayTimeRange,
        clientDisplayTimeRangeStartAt: formData.clientDisplayTimeRangeStartAt,
        clientDisplayTimeRangeEndAt: formData.clientDisplayTimeRangeEndAt,
      })
      if (err) return this.$message.error(err)
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      for (const key in props.configData) {
        formData[key] = props.configData[key]
      }
    }

    const disabledOption = (type) => {
      const selected = values(formData)
      const exist = selected.includes(type)
      return exist
    }

    onMounted(() => {
      syncData()
    })

    return { formData, formRef, formRules, updateFunctionMenu, find, disabledOption, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
